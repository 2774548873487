<template>
  <div class="main-wrapper events">

  <!--====================================
  ——— BEGIN MAIN SLIDE LIST
  ===================================== -->
  <section class="rev_slider_wrapper fullwidthbanner-container over" dir="ltr">

    <!-- the ID here will be used in the JavaScript below to initialize the slider -->
    <div id="rev_slider_1" class="rev_slider rev-slider-kidz-school" data-version="5.4.5" style="display:none">

      <ul>
        <!-- SLIDE 1  -->
        <li data-transition="fade">
          <img src="/assets/img/banner/slider-1/img-4.jpg" alt="Sky" class="rev-slidebg">
          <!-- LAYERS -->

          <!-- LAYER NR. 1 -->
          <div class="tp-caption tp-resizeme font-dosis font-weight-bold"
          data-frames='[{
          "delay":1600,"speed":1000,"frame":"500","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
          {"delay":"wait","speed":200,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'

          data-visibility="on"
          data-x="center"
          data-y="middle"
          data-hoffset="['0', '0', '0', '0']"
          data-voffset="['-75', '-75', '-65', '-45']"
          data-fontsize="['50', '45', '40', '30']"
          data-lineheight="['50', '45', '40', '30']"
          data-color="#FFF"
          data-width="auto"
          data-basealign="grid"
          data-responsive_offset="off"
          style="z-index: 1;">
          <span class="text-white">理財幼幼級</span>
        </div>

        <!-- LAYER NR. 2 -->
        <div class="tp-caption tp-resizeme"
        data-frames='[{
        "delay":2000,"speed":1300,"frame":"500","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
        {"delay":"wait","speed":200,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'

        data-visibility="on"
        data-x="center"
        data-y="middle"
        data-hoffset="['0', '0', '0', '0']"
        data-voffset="['0', '0', '0', '0']"
        data-width="['550','400','400','420']"
        data-fontsize="['25', '23', '23', '23']"
        data-lineheight="['30', '25', '25', '25']"
        data-color="#FFF"
        data-textAlign="center"
        data-basealign="grid"
        data-responsive_offset="on"
        data-responsive="on"
        data-type="text"
        data-whitespace="normal"
        style="z-index: 10;">
        你已經踏出⾦錢教育的第⼀步， 對⾦錢教育開始有興趣了
      </div>

      <!-- LAYER NR. 3 -->
      <a href="javascript:void(0)"
      class="tp-caption tp-resizeme slide-layer-3 btn btn-white"
      data-frames='[{
      "delay":2200,"speed":1300,"frame":"500","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
      {"delay":"wait","speed":200,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'

      data-visibility="on"
      data-x="center"
      data-y="middle"
      data-hoffset="['0', '0', '0', '0']"
      data-voffset="['90', '80', '70', '55']"
      data-width="['auto']"
      data-height="['auto']"
      data-fontsize="['18', '17', '16', '15']"
      data-lineheight="['25', '23', '21', '20']"
      data-color="#e7655a"
      data-textAlign="left"
      data-basealign="grid"
      data-responsive_offset="off"
      style="text-transform: uppercase; z-index: 1;"
      @click="checkGradeIsAvaliable($event, 1, '/fqgame/kindergarten')"
      >
      開始測驗
    </a>

  </li>

  <!-- SLIDE 2  -->
  <li data-transition="fade">
    <img src="/assets/img/banner/slider-1/img-1.jpg" alt="Sky" class="rev-slidebg">
    <!-- LAYERS -->

    <!-- LAYER NR. 1 -->
    <div class="tp-caption tp-resizeme font-dosis font-weight-bold"
    data-frames='[{
    "delay":1600,"speed":1000,"frame":"500","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
    {"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'

    data-visibility="on"
    data-x="left"
    data-y="middle"
    data-hoffset="['10', '40', '15', '30']"
    data-voffset="['-75', '-75', '-65', '-45']"
    data-fontsize="['50', '45', '40', '30']"
    data-lineheight="['50', '45', '40', '30']"
    data-color="#FFF"
    data-width="auto"
    data-basealign="grid"
    data-responsive_offset="off"
    style="z-index: 1;">
    <span class="text-white">理財國小級</span>
  </div>

  <!-- LAYER NR. 2 -->
  <div class="tp-caption tp-resizeme"
  data-frames='[{
  "delay":2000,"speed":1300,"frame":"500","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
  {"delay":"wait","speed":200,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'

  data-visibility="on"
  data-x="left"
  data-y="middle"
  data-hoffset="['10', '40', '15', '30']"
  data-voffset="['0', '0', '0', '0']"
  data-width="['550','500','500','420']"
  data-fontsize="['25', '23', '23', '23']"
  data-lineheight="['30', '25', '25', '25']"
  data-color="#FFF"
  data-textAlign="left"
  data-basealign="grid"
  data-responsive_offset="off"
  data-type="text"
  data-whitespace="normal"
  style="z-index: 10;">
  已經有⾦錢有初步的概念，也逐步提⾼⾃⼰理財知識，能夠分辨那些東⻄西是需要跟想要了
  </div>

  <!-- LAYER NR. 3 -->
  <a href="javascript:void(0)"
  class="tp-caption tp-resizeme slide-layer-3 btn btn-white"
  data-frames='[{
  "delay":2200,"speed":1300,"frame":"500","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
  {"delay":"wait","speed":200,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'

  data-visibility="on"
  data-x="left"
  data-y="middle"
  data-hoffset="['10', '40', '15', '30']"
  data-voffset="['90', '80', '70', '55']"
  data-width="['auto']"
  data-height="['auto']"
  data-fontsize="['18', '17', '16', '15']"
  data-lineheight="['25', '23', '21', '20']"
  data-color="#e7655a"
  data-textAlign="left"
  data-basealign="grid"
  data-responsive_offset="off"
  style="text-transform: uppercase; z-index: 1;"
  @click="checkGradeIsAvaliable($event, 2, '/fqgame/elementary')"
  >
  開始測驗
  </a>

  </li>

  <!-- SLIDE 3  -->
  <li data-transition="fade">
    <img src="/assets/img/banner/slider-1/img-2.jpg" alt="Sky" class="rev-slidebg">
    <!-- LAYERS-->

    <!-- LAYER NR. 1 -->
    <div class="tp-caption tp-resizeme font-dosis font-weight-bold"
    data-frames='[{
    "delay":1600,"speed":1000,"frame":"500","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
    {"delay":"wait","speed":200,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'

    data-visibility="on"
    data-x="center"
    data-y="middle"
    data-hoffset="['0', '0', '0', '0']"
    data-voffset="['-75', '-75', '-65', '-45']"
    data-fontsize="['50', '45', '40', '30']"
    data-lineheight="['50', '45', '40', '30']"
    data-color="#FFF"
    data-width="auto"
    data-basealign="grid"
    data-responsive_offset="off"
    style="z-index: 1;">
    <span class="text-white">理財國中級</span>
  </div>

  <!-- LAYER NR. 2 -->
  <div class="tp-caption tp-resizeme"
  data-frames='[{
  "delay":2000,"speed":1300,"frame":"500","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
  {"delay":"wait","speed":200,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'

  data-visibility="on"
  data-x="center"
  data-y="middle"
  data-hoffset="['0', '0', '0', '0']"
  data-voffset="['0', '0', '0', '0']"
  data-width="['550','400','400','420']"
  data-fontsize="['25', '23', '23', '23']"
  data-lineheight="['30', '25', '25', '25']"
  data-color="#FFF"
  data-textAlign="center"
  data-basealign="grid"
  data-responsive_offset="on"
  data-responsive="on"
  data-type="text"
  data-whitespace="normal"
  style="z-index: 10;">
  你的⾦融素養持續提⾼中，相信多數的FQ智慧王測驗題已經難不倒你了
  </div>

  <!-- LAYER NR. 3 -->
  <a href="javascript:void(0)"
  class="tp-caption tp-resizeme slide-layer-3 btn btn-white"
  data-frames='[{
  "delay":2200,"speed":1300,"frame":"500","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
  {"delay":"wait","speed":200,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'

  data-visibility="on"
  data-x="center"
  data-y="middle"
  data-hoffset="['0', '0', '0', '0']"
  data-voffset="['90', '80', '70', '55']"
  data-width="['auto']"
  data-height="['auto']"
  data-fontsize="['18', '17', '16', '15']"
  data-lineheight="['25', '23', '21', '20']"
  data-color="#e7655a"
  data-textAlign="left"
  data-basealign="grid"
  data-responsive_offset="off"
  style="text-transform: uppercase; z-index: 1;"
  @click="checkGradeIsAvaliable($event, 3, '/fqgame/junior')"
  >
  開始測驗
  </a>

  </li>

  <!-- SLIDE 4 -->
  <li data-transition="fade">
    <img src="/assets/img/banner/slider-1/img-3.jpg" alt="Sky" class="rev-slidebg">
    <!-- LAYERS -->

    <!-- LAYER NR. 1 -->
    <div class="tp-caption tp-resizeme font-dosis font-weight-bold"
    data-frames='[{
    "delay":1600,"speed":1200,"frame":"500","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
    {"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'

    data-visibility="on"
    data-x="['left','left','left','right']"
    data-y="middle"
    data-hoffset="['650', '500', '350', '60']"
    data-voffset="['-75', '-75', '-65', '-60']"
    data-fontsize="['50', '45', '40', '30']"
    data-lineheight="['50', '45', '40', '30']"
    data-color="#FFF"
    data-width="none"
    data-height="auto"
    data-basealign="grid"
    data-textAlign="['left','left','left','right']"
    data-type="text"
    data-responsive_offset="on"
    style="z-index: 1;">
    <span class="text-white">理財高中級</span>
  </div>

  <!-- LAYER NR. 2 -->
  <div class="tp-caption tp-resizeme"
  data-frames='[{
  "delay":2000,"speed":1300,"frame":"500","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
  {"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'

  data-visibility="on"
  data-x="['left','left','left','right']"
  data-y="middle"
  data-hoffset="['650', '500', '350', '60']"
  data-voffset="['0', '0', '0', '0']"
  data-width="['550','400','400','420']"
  data-fontsize="['25', '23', '23', '23']"
  data-lineheight="['30', '25', '25', '25']"
  data-color="#FFF"
  data-textAlign="['left', 'left', 'left', 'right']"
  data-basealign="grid"
  data-responsive_offset="on"
  data-type="text"
  data-whitespace="normal"
  style="z-index: 10;">
  你知道股票、基⾦、ETF及保險了， 課堂上的經濟學對你來說變簡單了
  </div>

  <!-- LAYER NR. 3 -->
  <a href="javascript:void(0)"
  class="tp-caption tp-resizeme slide-layer-3 btn btn-white"
  data-frames='[{
  "delay":2200,"speed":1300,"frame":"500","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
  {"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'

  data-visibility="on"
  data-x="['left','left','left','right']"
  data-y="middle"
  data-hoffset="['650', '500', '350', '60']"
  data-voffset="['90', '80', '75', '75']"
  data-width="none"
  data-height="none"
  data-fontsize="['18', '17', '16', '15']"
  data-lineheight="['25', '23', '21', '20']"
  data-color="#e7655a"
  data-basealign="grid"
  data-textAlign="['left','left','left','right']"
  data-responsive_offset="on"
  data-type="text"
  style="text-transform: uppercase; z-index: 1;"
  @click="checkGradeIsAvaliable($event, 4, '/fqgame/senior')"
  >
  開始測驗
  </a>

  </li>

  </ul>
  </div>
  </section>

  <!-- ====================================
  ——— BOX SECTION
  ===================================== -->
  <section class="section-top pt-9 py-md-7">
    <div class="container">
      <div class="row wow fadeInUp">

        <div class="col-sm-3 col-xs-12">
          <a href="javascript:void(0)" @click="checkGradeIsAvaliable($event, 1, '/fqgame/kindergarten')">
            <div class="card bg-success card-hover scrolling">
              <div class="card-body text-center p-0">
                <div class="card-icon-border-large border-success text-success">
                  <img class="" src="/assets/img/avator/kindergarten.jpg" alt="testimonial1.jpg">
                </div>
                <h2 class="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">理財幼幼級</h2>
                <a href="#teachers" class="btn-scroll-down d-block pb-4 pb-lg-5">
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-sm-3 col-xs-12">
          <a href="javascript:void(0)" @click="checkGradeIsAvaliable($event, 2, '/fqgame/elementary')">
            <div class="card bg-info card-hover scrolling">
              <div class="card-body text-center p-0">
                <div class="card-icon-border-large border-info text-info">
                  <img class="" src="/assets/img/avator/elementary.jpg" alt="testimonial1.jpg">
                </div>
                <h2 class="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">理財國小級</h2>
                <a href="#blog" class="btn-scroll-down d-block pb-4 pb-lg-5">
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-sm-3 col-xs-12">
          <a href="javascript:void(0)" @click="checkGradeIsAvaliable($event, 3, '/fqgame/junior')">
            <div class="card bg-danger card-hover scrolling">
              <div class="card-body text-center p-0">
                <div class="card-icon-border-large border-danger text-danger">
                  <img class="" src="/assets/img/avator/junior.jpg" alt="testimonial1.jpg">
                </div>
                <h2 class="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">理財國中級</h2>
                <a href="#gallery" class="btn-scroll-down d-block pb-4 pb-lg-5">
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-sm-3 col-xs-12">
          <a href="javascript:void(0)" @click="checkGradeIsAvaliable($event, 4, '/fqgame/senior')">
            <div class="card bg-warning card-hover scrolling">
              <div class="card-body text-center p-0">
                <div class="card-icon-border-large border-warning text-warning">
                  <img class="" src="/assets/img/avator/senior.jpg" alt="testimonial1.jpg">
                </div>
                <h2 class="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">理財高中級</h2>
                <a href="#courses" class="btn-scroll-down d-block pb-4 pb-lg-5">
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </a>
        </div>

      </div>
    </div>
  </section>

  <!-- ====================================
  ——— HOME FEATURE
  ===================================== -->
  <section class="pt-9 pb-6 py-md-7">
    <div class="container">
      <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp">
        <span class="shape shape-left bg-info"></span>
        <h2 class="text-danger">FQ大冒險-智慧王分級遊戲</h2>
        <span class="shape shape-right bg-info"></span>
      </div>

      <div class="row wow fadeInUp">

        <!-- Media -->
        <div class="col-md-6 col-sm-12">
          <div class="media mb-6">
            <div class="media-icon-large bg-success mr-xl-4">
              <i class="fa fa-leaf" aria-hidden="true"></i>
            </div>
            <div class="media-body">
              <h3 class="text-success">理財幼幼級</h3>
              <p>雖然你現在是幼幼班的⼀員，但是你已經踏出⾦錢教育的第⼀步， 對⾦錢教育開始有興趣了，未來只要多做FQ智慧王測驗，你的理財知識會越來越好。</p>
            </div>
          </div>
        </div>

        <!-- Media -->
        <div class="col-md-6 col-sm-12">
          <div class="media mb-6">
            <div class="media-icon-large bg-info mr-xl-4">
              <i class="fa fa-cutlery" aria-hidden="true"></i>
            </div>
            <div class="media-body">
              <h3 class="text-info">理財國小級</h3>
              <p>恭喜您升格為⼤哥哥，成為⼩學⽣了，你已經有⾦錢有初步的概念，也逐步提⾼⾃⼰理財知識，能夠分辨那些東⻄西是需要跟想要了，繼續努⼒持續多做FQ智慧王測驗，相信你的⾦融素養很快就超越其他同學了。</p>
            </div>
          </div>
        </div>

        <!-- Media -->
        <div class="col-md-6 col-sm-12">
          <div class="media mb-6">
            <div class="media-icon-large bg-danger mr-xl-4">
              <i class="fa fa-car" aria-hidden="true"></i>
            </div>
            <div class="media-body">
              <h3 class="text-danger">理財國中級</h3>
              <p>哇！你升級為國中⽣了，你的⾦融素養持續提⾼中，相信多數的FQ智慧王測驗題已經難不倒你了，記帳、零⽤錢理財、換匯，這些都是⼩兒科了，持續加油，很快你就會升格為⾼中⽣。</p>
            </div>
          </div>
        </div>

        <!-- Media -->
        <div class="col-md-6 col-sm-12">
          <div class="media mb-6">
            <div class="media-icon-large bg-warning mr-xl-4">
              <i class="fa fa-graduation-cap" aria-hidden="true"></i>
            </div>
            <div class="media-body">
              <h3 class="text-warning">理財高中級</h3>
              <p>出運啦！你升格為⾼中⽣了，你就是⼩⼤⼈了，你知道股票、基⾦、ETF及保險了， 課堂上的經濟學對你來說變簡單了，你只要持續多做FQ智慧王測驗，很快就升等為⼤學⽣，變⾝為投資新⼿。</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

</div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
const $ = window.$
export default {
  name: 'FQGame',
  mounted () {
    require('@/assets/js/base.js')
    this.setRevByJQuery()
  },
  methods: {
    /**
     * grade: 年級, link: 要去的連結
     */
    checkGradeIsAvaliable (e, grade, link) {
      /**
       * 點擊任一連結時打 check api，如果未登入就導向登入頁
       * 如果已登入，確認是否可玩該區塊遊戲
       * 如果可以就 push 進去
       * 如果不行就維持在此頁
       */
      const memberToken = localStorage.getItem('memberToken')
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`

      if (!memberToken) {
        alert('尚未登入，請先登入之後才能開始遊戲~')
        vm.$router.push('/login')
        return null
      }
      vm.axios.post(api, {
        memberToken,
        token: tokenGenerator()
      }).then(res => {
        const userInfo = res.data.content
        if (userInfo.grade < grade) {
          alert('年級不足，請先玩其他年級的遊戲喔')
        } else {
          vm.$router.push(link)
        }
      })
    },
    setRevByJQuery () {
      $('#rev_slider_1').show().revolution({
        delay: 5000,
        sliderLayout: 'fullwidth',
        sliderType: 'standard',
        responsiveLevels: [1171, 1025, 769, 480],
        gridwidth: [1171, 1025, 769, 480],
        gridheight: [560, 500, 450, 350],

        /* basic navigation arrows and bullets */
        navigation: {
          arrows: {
            enable: true,
            style: 'hesperiden',
            hide_onleave: false
          },
          bullets: {
            enable: false,
            style: 'hesperiden',
            hide_onleave: false,
            h_align: 'center',
            v_align: 'bottom',
            h_offset: 0,
            v_offset: 20,
            space: 5
          }
        },
        disableProgressBar: 'on'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/kidz.css";
</style>
